import { O8PipelineEventStatus, O8PipelineEventUpdate } from 'common.interfaces';
import type { ManagerOptions } from 'socket.io-client';
import { LiveUpdateSubscription } from './live-update.interface';

export const getWsReconnectTimeout = (attemptCount: number): number => {
  return Math.pow(attemptCount, 2) * 1000; /* Increase the waiting to reconnect with a power square function */
};

export const getCleanupTrackIds = (evt: O8PipelineEventUpdate, trackIds: string[]): string[] => {
  return trackIds.filter((id) => evt.eventMetadata.trackId !== id);
};

export const showPersistedEventError = (evt: O8PipelineEventUpdate, trackIds: string[]) => {
  return evt.status === O8PipelineEventStatus.DISCARDED && trackIds.some((id) => id === evt.eventMetadata.trackId);
};

export const getSocketOptions = (accessToken: string): Partial<ManagerOptions> => {
  return {
    extraHeaders: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const applyEventToSubs = (evt: unknown, subs: Set<LiveUpdateSubscription> | undefined) => {
  subs?.forEach((sub) => applyEventToSubscription(evt, sub));
};

export const applyEventToSubscription = (evt: unknown, sub: LiveUpdateSubscription) => {
  if (typeof sub.filter === 'function') {
    const isPassingFilter = sub.filter(evt);
    if (!isPassingFilter) {
      return;
    }
  }
  return sub.onEvent(evt);
};
